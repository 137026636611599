import React from "react"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/ui/breadcrumbs"

import pillar from "../../../static/images/pillars/pillar-5.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="Pillar 5" />
                <div className="container single-pillar">
                    <img src={pillar} alt="healthy man in garage" />
                    <Breadcrumbs loc="pillars" subloc="pillar 5" />
                    <h1>Pillar 5</h1>
                    <h2>Add quality years to your life</h2>
                    <p>
                        The National Institute of Health defines active life
                        expectancy as years of life without disease and without
                        physical and mental/thinking disability. This is an
                        important distinction from just life expectancy because
                        we aren’t just trying to add more years, we want more
                        years that we can enjoy. It doesn’t matter how old you
                        are but how you are old (er)!Our genetics only make up
                        about 1/3 of the equation for active life expectancy (at
                        least to about age 90). The other two thirds come from a
                        combination of lifestyle choices but especially
                        nutrition and exercise.
                    </p>

                    <h3>The Challenge</h3>
                    <p>
                        One of the big problems I see in my practice is people
                        who have been inactive for long periods due to injury or
                        lifestyle often don’t know where to start. Some are in
                        pain, some have issues with balance or mobility. Lots of
                        people I talk to sometimes even get bad advice-
                        including recommendations for limiting activity due to
                        age or arthritic conditions. That last one is especially
                        dangerous because current research shows very strong
                        evidence that inactivity worsens arthritic conditions.
                    </p>

                    <h3>Solution</h3>
                    <p>
                        We are hoping that we can help motivate you or a loved
                        one, to get moving and add some high-quality years to
                        yours or their life by signing up for one of our
                        programs . If you would like more information on
                        exercise or nutrition for aging or you just need help
                        with motivation, we highly encourage you to reach out
                        and get a free consultation and a complimentary
                        evaluation from Longevity Lab professionals.
                    </p>

                    <p>
                        <b>
                            Learn more about program by{" "}
                            <a href="mailto:info@longevitylabnyc.com">
                                contacting us
                            </a>
                        </b>
                    </p>
                    {/* <a className="button" href="mailto:info@longevitylabnyc.com" >Contact Us</a> */}
                </div>
            </Layout>
        )
    }
}

export default RootIndex
